import {
	Box,
	Button,
	Card,
	CardContent,
	TextField,
	InputAdornment,
	SvgIcon
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Search as SearchIcon } from 'react-feather';
import AddCustomer from './AddCustomer'

const CustomerListToolbar = (props) => (
	<Box {...props}>
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'flex-end'
			}}
		>
			{/* <Button>
				Import
      		</Button>
			<Button sx={{ mx: 1 }}>
				Export
      		</Button> */}
			<AddCustomer loadData={props.loadData}/>
		</Box>
		<Box sx={{ mt: 3 }}>
			<Card>
				<CardContent>
					<Box sx={{ maxWidth: 500 }}>
						<TextField
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SvgIcon
											fontSize="small"
											color="action"
										>
											<SearchIcon />
										</SvgIcon>
									</InputAdornment>
								)
							}}
							placeholder="Search customer"
							variant="outlined"
						/>
					</Box>
				</CardContent>
			</Card>
		</Box>
	</Box>
);

export default CustomerListToolbar;
